import React from 'react'
import PropTypes from 'prop-types'

import { Menu } from './Menu'
import Footer from './Footer'
import './layout.scss'

const Layout = (props) => {
  const { children, pageContext: { years = [], yearCount = {} } = {} } = props

  return (
    <>
      <Menu {...props} />
      <div className="wrapper">
        <main>{children}</main>
      </div>
      <Footer years={years} yearCount={yearCount} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
