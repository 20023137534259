/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
  window.addEventListener('load', () => {
    const scriptTag = document.createElement('script')
    scriptTag.id = 'art-loader'
    scriptTag.src = '/art-2-2024-05-06.js'
    document.body.appendChild(scriptTag)
  })
}
