import * as React from 'react'
import * as classNames from 'classnames'

import Snurr from './svgs/snurr.inline.svg'
import Turn from './svgs/turn.inline.svg'
import Green from './svgs/green.inline.svg'
import Main from './svgs/main.inline.svg'
import Left from './svgs/left.inline.svg'
import Right from './svgs/right.inline.svg'
import * as Styles from './svgBackground.module.scss'

export enum SvgNames {
  Turn = 'Turn',
  Snurr = 'Snurr',
  Green = 'Green',
  Main = 'Main',
  Left = 'Left',
  Right = 'Right',
}
interface SvgBackgroundProps {
  name?: SvgNames
  delay?: number
}

const SvgBackground:React.FC<SvgBackgroundProps> = ({ name }) => {
  const css = classNames('svg')
  const getSVG = () => {
    switch (name) {
      case SvgNames.Green:
        return <Green className={css} />
      case SvgNames.Snurr:
        return <Snurr className={css} />
      case SvgNames.Turn:
        return <Turn className={css} />
      case SvgNames.Main:
        return <Main className={css} />
      case SvgNames.Left:
        return <Left className={css} />
      case SvgNames.Right:
        return <Right className={css} />
      default:
        return <Snurr className={css} />
    }
  }
  return (
    <div className={Styles.holder}>
      { getSVG() }
    </div>
  )
}
export { SvgBackground }
