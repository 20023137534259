import * as React from 'react'
import * as classNames from 'classnames'
import { Link } from 'gatsby'

import { SvgBackground, SvgNames } from '../SvgBackground'
import * as Styles from './menu.module.scss';
const { useState, useEffect } = React

interface MenuProps {
  pageResources: {
    page: {
      path: string
    }
  }
  path: string
}
const menuData = [
  {
    svgName: SvgNames.Main,
    path: '/',
    cssClass: Styles.home,
    text: 'hearsay',
  },
  {
    svgName: SvgNames.Left,
    cssClass: Styles.item,
    path: '/best-of/2021',
    text: 'Best of 2021',
  },
  {
    svgName: SvgNames.Left,
    cssClass: Styles.item,
    path: '/best-of/2020',
    text: 'Best of 2020',
  },
  {
    svgName: SvgNames.Right,
    cssClass: classNames(Styles.item),
    path: '/best-of/jazz/2020',
    text: 'Best Jazz 2020',
  },
]

export const Menu: React.FC<MenuProps> = (props) => {
  const { pageResources } = props
  const { page } = pageResources || {}
  const { path: currentPath } = page || {}
  const [activeItem, setActiveIndex] = useState(-1)
  useEffect(() => {
    const menuIndex = menuData.findIndex((item) => {
      const { path } = item
      return path.replace('/', '') === (currentPath || '').replace('/', '')
    })
    setActiveIndex(menuIndex)
  }, [currentPath])

  const renderNav = (data, index) => {
    const { path, text, svgName, cssClass } = data
    const className = classNames(Styles.feature, cssClass, {
      [Styles.active]: index === activeItem,
    })
    const onClick = () => {
      setActiveIndex(index)
    }
    return (
      <li className={className} key={`item-${index}`}>
        <h1>
          <Link onClick={onClick} to={path} dangerouslySetInnerHTML={{ __html: text }} />
        </h1>
        <SvgBackground name={svgName} delay={index} />
      </li>
    )
  }
  const items = menuData.map(renderNav)
  return (
    <>
      <ul className={Styles.featureHolder}>{items}</ul>
    </>
  )
}
