import * as React from 'react'
import { Link } from 'gatsby'
import './footer.scss'

const Footer = ({ years = [], yearCount = {} }) => {
  const yearLinks = years.sort().map((year) => {
    const count = yearCount[year] || 0
    return (
      <Link key={`year-${year}`} to={`/year/${year}`}>
        {year} ({count})
      </Link>
    )
  })
  const renderYearLists = () => {
    if (yearLinks.length === 0) {
      return null
    }
    return (
      <div className="wrapper">
        <h3>Year lists</h3>
        <div className="year-links">{yearLinks}</div>
      </div>
    )
  }

  return (
    <>
      { renderYearLists() }
      <footer className="footer">
        2023, <a href="https://hearsay.se">hearsay.se</a>
      </footer>
    </>
  )
}

export default Footer
